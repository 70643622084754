import React, { useState, useRef } from 'react';
import { Storage, Auth } from "aws-amplify";
import { Form, Button, Alert, Spinner, Jumbotron } from 'react-bootstrap';
import { v4 as uuid } from "uuid";

import ImageUploader from 'react-images-upload';
import './custom-dropzone.css';

export default () => {
    const [disabledSubmit, isDisabledSubmit] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState();

    const [files, setFiles] = useState([]);
    const [detail, setDetail] = useState({});

    const imageUploaderRef = useRef(null);

    const onDrop = files => setFiles(files);

    const handleSubmit = async (event) => {
        event.preventDefault();
        isDisabledSubmit(true);

        if (!files || files.length === 0) {
            event.stopPropagation();
            displayAlert('warning');
            isDisabledSubmit(false);
            return;
        }

        let { username } = await Auth.currentAuthenticatedUser();
        let success = [], failures = [];
        setDetail({ success, failures });
        displayAlert('primary');

        for (let file of files) {
            let fileId;
            try {
                fileId = file.name.split(".")[0];
                await Storage.put(uuid(), file, {
                    metadata: {
                        fileId: fileId,
                        uploadedBy: username
                    },
                    progressCallback(progress) {
                        console.log(`Uploaded ${fileId}: ${progress.loaded}/${progress.total}`);
                    }
                });
                success.push(fileId);
            } catch (ex) {
                console.log(ex);
                failures.push(fileId);
            }
        }
        setDetail({ success, failures });

        if (success.length > 0) {
            displayAlert("success");
        } else {
            displayAlert("danger");
        }

        clearFiles();
        isDisabledSubmit(false);
    };

    const clearFiles = () =>  imageUploaderRef.current.state.pictures.forEach(picture => imageUploaderRef.current.removeImage(picture));

    const displayAlert = type => {
        setTypeAlert(type);
        setShowAlert(true)
    }

    const buildAlert = typeAlert => {
        switch (typeAlert) {
            case "success":
                return <>
                    <Alert.Heading>Images uploaded!</Alert.Heading>
                    <p>Your file are uploaded and are being processed now.<br />It will be available in GCS Portal in a few moments</p>
                    <p>You can return to <Alert.Link href="#/">Home</Alert.Link></p>
                    <hr />
                    <p>Current status:</p>
                    <h2>Success</h2>
                    <ul>
                        {detail.success.map(fileId => {
                            return <li>{fileId}</li>
                        })}
                    </ul>
                    <h2>Failures</h2>
                    <ul>
                        {detail.failures.map(fileId => {
                            return <li>{fileId}</li>
                        })}
                    </ul>
                </>;
            case "warning":
                return <>
                    <Alert.Heading>Missing fields</Alert.Heading>
                    <p>Please, validate that all fields are filled in.</p>
                </>;
            case "primary":
                return <>
                    <Alert.Heading>
                        <Spinner animation="border" role="status" /> Upload in progress
                    </Alert.Heading>
                </>
            default:
                return <>
                    <Alert.Heading>An error occurred!</Alert.Heading>
                    <p>Please, try again.</p>
                </>;
        }
    }

    return <>
        <Jumbotron>
            <h1>Multiple Upload</h1>
            <p>Please select a folder with product images. <br />The accepted format is .JPG. <br />All pics from this folder will be uploaded and stored in GCS Portal.</p>
        </Jumbotron>
        <Alert show={showAlert} variant={typeAlert} onClose={() => setShowAlert(false)} dismissible>
            {buildAlert(typeAlert)}
        </Alert>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="image">
                <Form.Label>Images *</Form.Label>
                <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    withLabel={false}
                    singleImage={false}
                    buttonText="Choose images"
                    onChange={onDrop}
                    imgExtension={['.jpg']}
                    accept="image/jpeg"
                    ref={imageUploaderRef} />
            </Form.Group>
            <Button type="submit" disabled={disabledSubmit}>Submit form</Button>
        </Form>
    </>
};