import React, { useState, useEffect, useRef } from 'react';
import { Storage, API, Auth } from "aws-amplify";
import { Form, Button, Alert, Jumbotron, Spinner } from 'react-bootstrap';
import { v4 as uuid } from "uuid";

import ImageUploader from 'react-images-upload';
import Select from 'react-select';
import './custom-dropzone.css';

export default () => {
    const [disabledSubmit, isDisabledSubmit] = useState(false);

    const [loading, isLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [typeAlert, setTypeAlert] = useState();

    const [files, setFiles] = useState([]);
    const [fileId, setFileId] = useState();
    const [options, setOptions] = useState([]);

    const imageUploaderRef = useRef(null);

    const onDrop = files => setFiles(files);

    useEffect(() => {
        API.get("API", "/products")
            .then(({ products }) => setOptions(products))
            .then(() => isLoading(false))
            .catch(err => {
                console.log({ err });
                isLoading(false);
                displayAlert("danger");
            });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        isDisabledSubmit(true);

        if (!fileId || !files || files.length === 0) {
            event.stopPropagation();
            displayAlert('warning');
            isDisabledSubmit(false);
            return;
        }

        let { username } = await Auth.currentAuthenticatedUser();
        displayAlert('primary');

        Storage.put(uuid(), files[0], {
            metadata: {
                fileId: `${fileId}`,
                uploadedBy: username
            },
            progressCallback(progress) {
                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            }
        })
            .then(() => {
                displayAlert("success");
                clearFiles();
                isDisabledSubmit(false);
            })
            .catch(error => {
                console.error({ error });
                displayAlert("danger");
                isDisabledSubmit(false);
            })
    };

    const clearFiles = () => imageUploaderRef.current.state.pictures.forEach(picture => imageUploaderRef.current.removeImage(picture));

    const displayAlert = type => {
        setTypeAlert(type);
        setShowAlert(true)
    }

    const buildAlert = typeAlert => {
        switch (typeAlert) {
            case "success":
                return <>
                    <Alert.Heading>Image uploaded!</Alert.Heading>
                    <p>Your file is uploaded and is being processed now.<br />It will be available in GCS Portal in a few moments.</p>
                    <p>You can return to <Alert.Link href="#/">Home</Alert.Link></p>
                </>;
            case "warning":
                return <>
                    <Alert.Heading>Missing fields</Alert.Heading>
                    <p>Please, validate that all fields are filled in.</p>
                </>;
            case "primary":
                return <>
                    <Alert.Heading>
                        <Spinner animation="border" role="status" /> Upload in progress
                    </Alert.Heading>
                </>
            default:
                return <>
                    <Alert.Heading>An error occurred!</Alert.Heading>
                    <p>Please, try again.</p>
                </>;
        }
    }

    return <>
        <Jumbotron>
            <h1>Single Upload</h1>
            <p>Please select a product. <br />The accepted format is .JPG.</p>
        </Jumbotron>
        {loading ? <>
            <Spinner animation="border" role="status" /> Loading...
        </>
            :
            <>
                <Alert show={showAlert} variant={typeAlert} onClose={() => setShowAlert(false)} dismissible>
                    {buildAlert(typeAlert)}
                </Alert>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="id">
                        <Form.Label>Product ID *</Form.Label>
                        <Select
                            options={options}
                            onChange={({ value }) => setFileId(value)}
                            isSearchable={true}
                            isClearable={true}
                            name="fileId"
                            placeholder="Product ID..." />
                    </Form.Group>
                    <Form.Group controlId="image">
                        <Form.Label>Image *</Form.Label>
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            withLabel={false}
                            singleImage={true}
                            buttonText="Choose an image"
                            onChange={onDrop}
                            imgExtension={['.jpg']}
                            accept="image/jpeg"
                            ref={imageUploaderRef} />
                    </Form.Group>
                    <Button type="submit" disabled={disabledSubmit}>Submit form</Button>
                </Form>
            </>
        }
    </>
};