import { Auth } from "aws-amplify";

export default {
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        identityPoolRegion: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT,
        oauth: {
            domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN_NAME,
            scope: ["email", "profile", "openid"],
            redirectSignIn: window.location.origin,
            redirectSignOut: window.location.origin,
            responseType: "code"
        }
    },
    API: {
        endpoints: [
            {
                name: "API",
                endpoint: process.env.REACT_APP_AWS_API_GATEWAY,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_AWS_S3_UPLOAD_BUCKET,
            region: process.env.REACT_APP_AWS_REGION
        }
    }
};