import React, { useState, useEffect } from 'react';
import { Switch, Route } from "react-router-dom";
import './App.css';

import { I18n, Auth, Hub } from "aws-amplify";
import { AmplifySignOut } from '@aws-amplify/ui-react';

import { Container, Navbar, Nav, Jumbotron, Button } from 'react-bootstrap';

import translations from "./config/translations";

import Home from "./pages/Home";
import Upload from "./pages/Upload";
import MultipleUpload from './pages/Upload/multiple';

// Put Vocabularies
I18n.putVocabulariesForLanguage("en-US", translations);

// Create App
const AuthStateApp = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          console.log("Unknown state", { event, data });
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  return user ? (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="#/">GCS Admin - Interface</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#/">Home</Nav.Link>
            <Nav.Link href="#/upload">Upload single image</Nav.Link>
            <Nav.Link href="#/multiple-uploads">Upload multiple images</Nav.Link>
          </Nav>
          <Nav>
            <Navbar.Text style={{ marginRight: "10px" }}>
              Signed in as: {user.signInUserSession.idToken.payload.name} {user.signInUserSession.idToken.payload.given_name} ({user.username})
            </Navbar.Text>
            <AmplifySignOut />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container className="p-3">
        <Switch>
          <Route path="/upload">
            <Upload />
          </Route>
          <Route path="/multiple-uploads">
            <MultipleUpload />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Container>
    </>
  ) : (
      <>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="#/">GCS Admin - Interface</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Navbar>
        <Container className="p-3">
          <Jumbotron>
            <h1>Connection to GCS Admin</h1>
            <p>Please, connect with your credentials to access the application.</p>
            <p>
              <Button variant="primary" onClick={() => Auth.federatedSignIn()}>Open login page</Button>
            </p>
          </Jumbotron>
        </Container>
      </>
    );
}

// Export
export default AuthStateApp;